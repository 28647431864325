<template>

<div  class="kw-tabs-wrapper-content">
  <span class="resize-loading" v-if="loading">
      <span class="loader"></span>
  </span>
    <h3>
      {{ $t("ORGANIZATIONS.VIEW_ELIXIO_SYNC") }}
    </h3>
    <!-- <div class="page-wrapper-table-header">
      <div class="page-wrapper-table-header-left">

        <base-input
          v-model="query"
          class="search"
          type="search"
          prepend-icon="far fa-search"
          :placeholder="$t('COMMON.SEARCH')"
          clearable
        />

      </div>

      <div class="page-wrapper-table-header-right">
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
    </div> -->
    <div class="page-wrapper-table-body">
      
      <el-row :gutter="20">
        <el-col :span="12"
         v-for="(service) in services" :key="service.slug">
          <el-card 
           class="service-card" 
           shadows="hover"
          
          >

            <!-- Badge dans le coin supérieur droit -->
          <div 
          class="service-badge"
          :class="service.is_active ? 'badge-success' : 'badge-default'"
          >
            <i class="fa fa-check" v-if="service.is_active" ></i>
            <i class="fa fa-ban" v-else></i>
          </div>
            <div class="service-card-content">
              <!-- Image à gauche -->
              <img :src="service.logo" 
                styles="width:250px"
                alt="Service Image" 
                class="service-image" 
              />

              <!-- Informations à droite -->
              <div class="service-info">
                <h3>{{ service.name }}</h3>
                <!-- <p><strong> {{ service.slug }}</strong></p> -->
                <p class="text-xs" 
                  v-if="service.description"
                  v-html="service.description">
                </p>
                
                <!-- Switch pour activer/désactiver -->
                <div class="service-switch d-flex">
                  <!-- <el-switch
                    :model-value="service.is_active"
                    :active-text="service.is_active ? 'Re-Authoriser' : 'Authoriser'"
                    @change="authorize(service)"
                  /> -->

                 <span class="text-xs" v-if="service.is_active">
                    <a
                      href="javascript:void(0)"
                      @click.prevent="viewService(service)"
                    >
                    {{ 'Configurer'  }}
                    </a>
                </span> 

                <span class="ml-2">
                  <ElixioButtonAuthorizeService
                    :organization="organization"
                    :service="service"
                  />  
                </span> 
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
</div>

    
 
</template>

<script>
import swal from "sweetalert2";
import  _ from "lodash";
import { BasePagination } from "@/components";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Row,
  Tag,
  Col,
  Card,
  Switch,
} from "element-ui";
import ElixioButtonAuthorizeService from "@/components/Elixio/ButtonAuthorizeService.vue";


export default {
  name: "ElixioServicesList",

  components: { 
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Tag.name]: Tag,
    [Row.name]: Row,
    [Col.name]: Col,
    [Card.name]: Card,
    [Switch.name]: Switch,
    ElixioButtonAuthorizeService,
  },

  props: [
    "organization"
  ],

  data() {
    return {
      services : [] ,
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      loading: true,
      selectedOrganization: this.organization,
    
    };
  },

  computed: {},

  created() {},

  methods: {

    getListDebounced: _.debounce(function () {
      this.getAll();
    }, 300),

    async getAll(){
      try {
    
        this.loading = true;
        const organizationId = this.selectedOrganization.id;
      
       const response = await this.$store.dispatch(
                                  "elixioServicesModule/getAllServices",
                                  organizationId
                                );

       this.services = response.data;       
      
        
      } catch (error) {
      //  await this.showRequestError(error);
        console.error("Listservice => ", error)
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }finally{
          this.loading = false;
      }
    },
     sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getAll();
    },

     async deleteService(service) {
        this.$emit("onDeleteService", service);
      },
  
      async viewService(service) {
      //  console.log("viewService => ", service)
        this.$emit("onSelectedService", service);

      },
  
      async editService(service) {
        this.$emit("onUpdateService", service);
      },
      toggleService(service) {
        // Émet un événement pour notifier le parent du changement d'état
        this.$emit("onToggleService", service);
     },

  },

  mounted() {},

  watch: {
    organization: {
      handler: "getListDebounced",
      immediate: true,
    },
  },
};
</script>

<style scoped>

.service-card {
  position: relative; /* Nécessaire pour positionner le badge */
  border: 1px solid #ebeef5;
  border-radius: 8px;
  overflow: hidden;
  padding: 16px;
  display: flex;
  align-items: center;
}

.service-badge {
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-color: #eee;
}

.service-badge .badge-success {
  background-color: #52c41a !important; /* Couleur verte pour indiquer que le service est actif */
  color: white !important;
  border-color: #52c41a !important;
}
.service-badge .badge-default {
  background-color: rgb(206, 212, 203) !important; /* Couleur verte pour indiquer que le service est actif */
  color: white !important;
  border-color: #52c41a !important;
}
.service-card-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.service-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 16px;
  border-radius: 8px;
}

.service-info {
  flex: 1;
}

.service-info h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.service-info p {
  margin: 4px 0;
}

.service-switch {
  margin-top: 16px;
}

</style>
