<template>
  <div>
  
    <elixio-services-list
       v-if="isViewList"
        :organization = "selectedOrganization"
        @onSelectedService="handleServiceSelected"
        ref="servicesList"
    />

    <elixio-view-service 
       v-else
        :service="service"
        :organization = "selectedOrganization"
        @onSubmited="handleServiceSubmited"
        @onClosed="handleViewClosed"
        @onSusbcribed="handleSusbcribed"
      />
    <!-- <elixio-create-service
      v-if="isCreateModalOpen"
      :service="service"
      :onCreated="handleServiceCreated"
    />

    <elixio-update-service
      v-if="isUpdateModalOpen"
      :service="service"
      :onUpdated="handleServiceUpdated"
    /> -->

   
    
  </div>
</template>

<script>
//import OrganizationViewLogs from "../partials/OrganizationViewLogs.vue";
import ElixioServicesList from "@/components/Elixio/ServicesList.vue";
import ElixioCreateService from "@/components/Elixio/CreateService.vue";
import ElixioUpdateService from "@/components/Elixio/UpdateService.vue";
import ElixioViewService from "@/components/Elixio/ViewService.vue";

export default {
  name: "organization-view-elixio-sync",

  components: {
    ElixioCreateService,
    ElixioUpdateService,
    ElixioViewService,
    ElixioServicesList,
  },

  props: ["organization"],

  data() {
    return {
      isCreateModalOpen : false,
      isUpdateModalOpen : false,
      isViewModalOpen : false,
      isViewList : true,
      service  : null,
      selectedOrganization  : this.organization,
    };
  },

  computed: {},

  created() {},

  methods: {

    handleServiceCreated(){
      this.isCreateModalOpen = true;
    },

    handleServiceUpdated(){
      this.isCreateModalOpen = true;
    },

    handleServiceDeleted(){
      this.refreshList();
    },

    handleServiceSubmited({success,service}){
      this.isViewList = success;

      if(success){
        this.refreshList();
      }
    },
    handleViewClosed(){
      this.isViewList = true;
    },
    
    handleSusbcribed(success){
     
    },
    
    handleServiceSelected(service){
      this.service = service;
    //  this.isCreateModalOpen = true;
      this.isViewList = false;
      console.log("ServiceSelected => " , service)
     
    },

    closeModals(){
      this.service = null;
      this.isViewList = true
      this.isViewModalOpen = false;
      this.isCreateModalOpen = false;
      this.isUpdateModalOpen = false;
    },
   

    async refreshList(){
      this.closeModals();
       return await this.$refs.servicesList.getAll()
    }
   
  },

  mounted() {},

  watch: {
   
  },
};
</script>
