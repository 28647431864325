<template>
  <div>
    
    
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

// import ElixioServicesSelector from "@/components/PermissionsTable.vue";

export default {
  name: "ElixioCreateService",

  components: { 
    
  },

  props: [
    "action",
    "service",
  ],

  data() {
    const serviceData = cloneDeep(this.service ?? emptyService)
    return {
      serviceData,
      loading : false,
    };
  },

  computed: {},

  created() {},

  methods: {

    async create(){

    }
  },

  mounted() {},

  watch: {
   
  },
};
</script>
