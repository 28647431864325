<template>
 <div class="text-xs">
    
    <a
      href="javascript:void(0)"
      @click.prevent="handleAuthorize"
    >
    {{ service.is_active ? "Re-autoriser" : 'Autoriser' }}
    </a>
</div> 
</template>

<script>
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
// import ElixioServicesSelector from "@/components/PermissionsTable.vue";

export default {
  name: "ElixioButtonAuthorizeService",

  components: { 
    
  },

  props: [
    "service",
    "organization"
  ],

  data() {
    return {
      loading : false,
    };
  },

  computed: {},

  created() {},

  methods: {

  
       async subscribe(){
            this.loading = true;
            let success  = false;
            const service = this.service;
      
              try {
      
                const formData = {
                  organizationId : this.organization.id,
                  payload : {
                    service_name : service.name,
                    service_type : service.slug,
                    // test_config  : service.test_config,
                    // prod_config  : service.prod_config,
      
                    //test_config  : [],
                    //prod_config  : [],
                  },
                };
            
                console.clear()
               
                const response = await this.$store.dispatch(
                                              "elixioServicesModule/subscribe",
                                              formData
                                            );
      
        
                  if(response.status === 200){
      
                     // this.subscribed = true;
      
                      if (("auth_data" in response.data)) {
                        
                        const {data} = response;
      
                         this.$notify({
                            type: "success",
                            message: data.message ?? "Reuissie",
                          });
                    
                          const auth_url = data.auth_data.auth_url ?? null;
                          const oauth_redirect_uri = data.auth_data.oauth_redirect_uri ?? null;
                          success = true;
      
                          if(auth_url){
      
                            swal.close();
      
                            // Le campong doit authoriser la connexion manuellement
                            window.open(auth_url,'_blank',  'width=600,height=600');
      
                          }else{
                            this.$notify({
                                type: "warning",
                                message:  "Aucune URL d'authentification trouvée",
                              });
                          }
                        // await this.getAll();  
                      }
                  }
              } catch (error) {
      
                console.error("Subscribe => ", error);
      
                swal.showValidationMessage(`Erreur: ${error}`); // Gère les erreurs
      
                this.$notify({
                  type: "danger",
                  message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
                });
      
              // this.formErrors = error.response.data.errors;
                
              }finally{
                this.loading = false;
                this.$emit("onSubscribed",success)
              }
       },   
      
      async handleAuthorize(){
  
        return await swal.fire({
      
                title: this.$t("ORGANIZATIONS.ELIXIO_SYNC_CONFIRM_THIS_SUBSCRIPTION"),
                icon: "question",
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: this.$t("COMMON.YES"),
                cancelButtonText: this.$t("COMMON.NO"),
                confirmButtonClass: "btn btn-primary",
                cancelButtonClass: "btn btn-warning",
                showLoaderOnConfirm : this.loading,
                allowOutsideClick: () => !swal.isLoading(), // Empêche la fermeture accidentelle pendant le chargement
  
                preConfirm : async ()=> {
                  return await this.subscribe();
                }
              });
  
      },
  },

  mounted() {},

  watch: {
   
  },
};
</script>
