<template>
 <div  class="container">
  <span class="resize-loading" v-if="loading">
      <span class="loader"></span>
  </span>

  <div class="form-wrapper full">
    <ElixioServiceAdditionalConfigEditor
        v-model  = "serviceData.additional_config"
        :fields  ="additionalConfigFields"
        :disabled ="loading"
        :title="$t('ORGANIZATIONS.ELIXIO_ADDITIONNAL_CONFIG_TITLE')"
      />
  </div>
   
  <div class="form-wrapper full" v-if="credentialsFields">
   
      <el-tabs v-model="activeTabMode" :stretch="true">
        <el-tab-pane
          :label="$t('ORGANIZATIONS.ELIXIO_SYNC_TEST_CONFIG')"
          :name="SERVICE_MODE_TEST"
        >

          <ElixioServiceConfigEditor
            v-model = "test_config"
            :serviceType ="serviceData.slug"
            :fields ="credentialsFields"
            :disabled ="loading"
            :title ="$t('ORGANIZATIONS.ELIXIO_SYNC_TEST_CONFIG')"
          />

        </el-tab-pane>

        <el-tab-pane
          :label="$t('ORGANIZATIONS.ELIXIO_SYNC_PROD_CONFIG')"
          :name="SERVICE_MODE_PROD"
        >

          <ElixioServiceConfigEditor
            v-model = "prod_config"
            :serviceType="serviceData.slug"
            :fields="credentialsFields"
            :disabled="loading"
            :title="$t('ORGANIZATIONS.ELIXIO_SYNC_PROD_CONFIG')"
          />

        </el-tab-pane>
      </el-tabs>
  </div>

  <div class="form-wrapper full" v-else-if="subscribed && serviceData.instructions">
    <p v-html="serviceData.instructions"></p>
  </div>

    <div class="elite-modal-forms">
      <button
          type="submit"
          class="btn edit"
          @click="handleSubmit"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("ORGANIZATIONS.ELIXIO_SYNC_SUBMIT") }}
        </button>

        <button
          type="button"
          class="btn close"
          @click="close"
          :disabled="loading"
        >
          {{ $t("COMMON.CLOSE") }}
        </button>
       
        

    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { 
  Select,
     Option,
     Row,
     Col ,
     Card,
     Button,
     Tabs, 
     TabPane,
     Form,
     Radio,
     RadioGroup,
} from "element-ui";

import swal from "sweetalert2";

import ElixioServiceConfigEditor from "@/components/Elixio/ServiceConfigEditor.vue";
import ElixioServiceAdditionalConfigEditor from "@/components/Elixio/ServiceAdditionalConfigEditor.vue";

import {
  SERVICE_MODE_TEST,
  SERVICE_MODE_PROD,
} from "@/constants/elixioServices";

import {
  QUERY_ACTIONS_ADD,
} from "@/constants/common";


export default {
  name: "ElixioServiceForm",

  components: { 
    [Button.name]: Button,
    [Card.name]: Card,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    ElixioServiceConfigEditor,
    ElixioServiceAdditionalConfigEditor
  },

  props: [
    "action",
    "service",
    "organization",
  ],

  data() {
     const serviceData = cloneDeep(this.service);
    
    return {
     
      loading : false,
      subscribed : false,
      SERVICE_MODE_TEST,
      SERVICE_MODE_PROD,
      serviceData,
      activeTabMode : serviceData?.mode || SERVICE_MODE_TEST,
      test_config : serviceData?.test_config || [],
      prod_config : serviceData?.prod_config || [],
    };
  },

  computed: {
    isCreate(){
     return (this.action === QUERY_ACTIONS_ADD);
    },

    credentialsFields(){
     return this.service.fields.creadentials;
    },

    additionalConfigFields(){
     return this.service.fields.additional_config;
    },

  
    payload(){
      const {test_config,prod_config,name,slug}= this.serviceData;
  
      return {
        service_name : name,
        service_type : slug,
        test_config : {...this.test_config},
        prod_config : {...this.prod_config},
      }
    },

    
  },

  created() {
   
  },

  methods: {    

   async handleSubmit(){

      const that = this;
      return await swal.fire({
    
              title: this.$t("ORGANIZATIONS.ELIXIO_SYNC_CONFIRM_THIS_SUBSCRIPTION"),
              icon: "question",
              buttonsStyling: false,
              showCancelButton: true,
              confirmButtonText: that.$t("COMMON.YES"),
              cancelButtonText: that.$t("COMMON.NO"),
              confirmButtonClass: "btn btn-primary",
              cancelButtonClass: "btn btn-warning",
              showLoaderOnConfirm : that.loading,
              allowOutsideClick: () => !swal.isLoading(), // Empêche la fermeture accidentelle pendant le chargement

              preConfirm : async ()=> {
                await that.submitConfig();
              }
            });

    },

    async submitConfig(){
      this.loading = true;
      let success  = false;

      const organizationId = this.organization.id;
      const service = this.serviceData;
      
      const payload = {
              additional_config : {...service.additional_config},
              service_name : service.name,
              service_type : service.slug,
              // test_config  : service.test_config,
                // prod_config  : service.prod_config,
            }

        try {

          const formData = { organizationId,payload };
      
          console.clear();
         
          const response = await this.$store.dispatch(
                                    "elixioServicesModule/updateConfigs",
                                    formData
                                  );

            if(response.status === 200){

              this.$notify({
                    type: "success",
                    message: response.data?.message ?? "Reuissie",
                });

                success = true;

            }
        } catch (error) {

          console.error("submitConfig => ", error);

          swal.showValidationMessage(`Erreur: ${error}`); // Gère les erreurs

          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });

        // this.formErrors = error.response.data.errors;
          
        }finally{
          this.loading = false;
          this.$emit("onSubmited", {success , service });
        }
     },

    async subscribe(){
      this.loading = true;
      let success  = false;

        try {

          const formData = {
            organizationId : this.organization.id,
            payload : {
              service_name : this.serviceData.name,
              service_type : this.serviceData.slug,
              // test_config  : service.test_config,
              // prod_config  : service.prod_config,

              //test_config  : [],
              //prod_config  : [],
            },
          };
      
          console.clear()
         
          const response = await this.$store.dispatch(
                                        "elixioServicesModule/subscribe",
                                        formData
                                      );

  
            if(response.status === 200){

               // this.subscribed = true;

                if (("auth_data" in response.data)) {
                  
                  const {data} = response;

                   this.$notify({
                      type: "success",
                      message: data.message ?? "Reuissie",
                    });
              
                    const auth_url = data.auth_data.auth_url ?? null;
                    const oauth_redirect_uri = data.auth_data.oauth_redirect_uri ?? null;
                    success = true;

                    if(auth_url){

                      swal.close();

                      // Le campong doit authoriser la connexion manuellement
                      window.open(auth_url,'_blank',  'width=600,height=600');

                    }else{
                      this.$notify({
                          type: "warning",
                          message:  "Aucune URL d'authentification trouvée",
                        });
                    }
                   await this.getAll();  
                }
            }
        } catch (error) {

          console.error("Subscribe => ", error);

          swal.showValidationMessage(`Erreur: ${error}`); // Gère les erreurs

          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });

        // this.formErrors = error.response.data.errors;
          
        }finally{
          this.loading = false;
          this.$emit("onSubmited", {success , service });
        }
     },   

     async authorize(){

        return await swal.fire({
      
                title: this.$t("ORGANIZATIONS.ELIXIO_SYNC_CONFIRM_THIS_SUBSCRIPTION"),
                icon: "question",
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: this.$t("COMMON.YES"),
                cancelButtonText: this.$t("COMMON.NO"),
                confirmButtonClass: "btn btn-primary",
                cancelButtonClass: "btn btn-warning",
                showLoaderOnConfirm : this.loading,
                allowOutsideClick: () => !swal.isLoading(), // Empêche la fermeture accidentelle pendant le chargement

                preConfirm : async ()=> {
                  return await this.subscribe();
                }
              });
     },

   
   close(refresh = false){
    this.$emit("onCancelled");
   }
  },

  mounted() {},

  watch: {
    test_config: {
      handler(newVal) {
       // console.log("test_config => ",newVal)
        
      },
      deep: true,
      immediate: true,
    },
    prod_config: {
      handler(newVal) {
       // console.log("prod_config => ",newVal)
        
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
