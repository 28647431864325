<template>
 <div  class="kw-tabs-wrapper-content">
    <h3>
      {{ $t("ORGANIZATIONS.VIEW_ELIXIO_SYNC") }} - 
      {{ serviceData.name }}

      <span class="ml-5">
        <ElixioButtonAuthorizeService 
          :organization="organization"
          :service="service"
        />
      </span> 
    </h3>

    

    <ElixioServiceForm
      :service="service"
      :organization="currentOrganization"
      @onSubmited="handleFormSubmited"
      @onCancelled="handleFormCancelled"
     /> 
  </div>

</template>

<script>
import { cloneDeep } from "lodash";
import requestErrorMixin from "@/mixins/request-error-mixin";
import swal from "sweetalert2";

import ElixioServiceForm from "@/components/Elixio/ServiceForm.vue";
import ElixioButtonAuthorizeService from "@/components/Elixio/ButtonAuthorizeService.vue";

export default {
  name: "ElixioViewService",

  components: { 
    ElixioServiceForm,
    ElixioButtonAuthorizeService ,
  },

  props: [
    "action",
    "service",
    "organization",
  ],

  data() {
    const serviceData = cloneDeep(this.service ?? emptyService)
    const currentOrganization = cloneDeep(this.organization)
    
    return {
      serviceData,
      currentOrganization,
      loading : false,
    };
  },

  computed: {},

  created() {},

  methods: {

    handleFormSubmited({success, service}){
       this.$emit("onSubmited",{success, service})
    },
    handleFormCancelled(){
       this.$emit("onClosed")
    },

  },

  mounted() {},

  watch: {
   
  },
};
</script>
