<template>
  <div class="container-fluids" v-if="serviceType">
        <div >
          <h5 v-if="title">{{ title }}</h5>
     
          <div class="form-wrapper full" 
            v-for="field in fields" 
            :key="field.key">

            <div v-if="field.type === 'boolean'" >
                <base-checkbox
                    :disabled="isDisabled"
                    v-model="config[field.key]"
                    class="mb-2"
                 >
                  <span class="form-control-label">
                      {{ field.label.toUpperCase() }}
                  </span>
                </base-checkbox>
            </div>
            
            <base-input 
              v-else
                v-model="config[field.key]"
                :type="field.type"
                :label="field.label.toUpperCase()"
                :disabled="isDisabled"
                :placeholder="field.label"
                class="mb-0"
                :rows="(field.type === 'textarea') ? 4 : undefined"
           />
        
          
          <small v-if="field?.description ?? null" class="text-xs">
                {{ field.description }}
            </small>
            
            <!-- <validation-error :errors="apiValidationErrors.config[field.key]" /> -->

        </div>
    </div>
  </div>
</template>

<script>
import { Select, Option, Row, Col, Card, Button } from "element-ui";
import alertLeave from "@/mixins/alert-leave-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseCheckbox from "@/components/Inputs/BaseCheckbox.vue";
import { SERVICES_TYPES } from "@/constants/elixioServices";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";


export default {

  name: "ElixioServiceConfigEditor",


  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Card.name]: Card,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    BaseInput,
    BaseCheckbox,
  },
 
  props : [
  'value',
  "serviceType",
  "title",
  "disabled",
  "fields",
],

  mixins: [alertLeave,formMixin],

  data() {
    return {
      config : this.value || {},
      serviceFields: this.fields || {},
    };
  },
  

  computed: {
  
    isDisabled() {
      return this.disabled ?? false;
    },
    
  },
  watch: {
    value: {
      handler(newVal) {
        this.config = newVal ?? {};
      },
      deep: true,
      immediate: true,
    },
    config: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
  },
};
</script>
